<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          <div v-if="data.moveid === null">
            New move
          </div>
          <div v-else>
            Edit move - {{ move.Name }}
          </div>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <table class="table table-google">
          <thead>
            <tr>
              <th colspan="2">Move details</th>
            </tr>
          </thead>
          <tbody v-if="moveReady">
            <tr>
              <td>Name</td>
              <td>
                <b-form-input
                  v-model="move.Name"
                  placeholder="Name"
                  size="sm"
                  autocomplete="off"
                  trim
                  :disabled="isLoading"
                  autofocus
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td>Type</td>
              <td>
                <b-form-select
                  v-model="move.Typ"
                  :options="types"
                  size="sm"
                ></b-form-select>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">
                Loading move..
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'MoveModal',
  components: {},
  props: {
    data: Object,
    types: Array,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (this.move.Name.length === 0) {
        return false;
      }
      if (this.move.Typ.length === 0) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      move: {
        Name: '',
        Type: '',
      },
      moveReady: false,
      filters: {},
      options: {},
    };
  },
  methods: {
    fetchMove() {
      if (this.data.moveid === null) {
        this.moveReady = true;
        return;
      }
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.move' })
        .send({ method: 'moves' })
        .then((res) => {
          this.move = res.body.find((item) => item.Id === this.data.moveid);
          this.moveReady = true;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchMove();
    },
    createMove() {
      const params = {
        name: this.move.Name,
        typ: this.move.Typ,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.move' })
        .send({ method: 'add' })
        .send({ params })
        .then(() => {
          this.$emit('saved');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    updateMove() {
      const params = {
        moveid: this.data.moveid,
        name: this.move.Name,
        typ: this.move.Typ,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.move' })
        .send({ method: 'edit' })
        .send({ params })
        .then(() => {
          this.$emit('saved');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onSave() {
      if (this.data.moveid === null) {
        this.createMove();
      } else {
        this.updateMove();
      }
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
